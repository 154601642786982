<template>
  <layout-full-width :title="$t('enums.projectStatusDescShort.PROJECT_NEW')">
    <component-project/>
  </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentProject from "@/components/ComponentProject";

export default {
  name: "PageNewProject",
  components: { LayoutFullWidth, ComponentProject}
}
</script>
<style scoped lang="scss"></style>